<template>
  <div class="row">
    <div class="col-auto">
      <div class="form-group">
        <label class="mb-1">Choose a file to upload</label>
        <small class="form-text text-muted"
          >Please upload a comma delimited CSV file <u>without</u> a header.</small
        >
        <FileUploader
          @uploaded="fileUploaded"
          button-text="Choose file"
          button-class="btn-primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FileUploader from './CustomerImportFileUploader'
export default {
  name: 'CustomerImportUpload',
  components: {
    FileUploader,
  },
  methods: {
    async fileUploaded(fileUrl) {
      this.$emit('finished', fileUrl)
    },
  },
}
</script>

<style scoped lang="scss"></style>
