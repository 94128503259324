<template>
  <div>
    <div v-if="samples.length">
      <div class="card">
        <div class="card-body">
          <h2 class="mt-3 mb-2">Map your Fields</h2>
          <p class="mb-4 text-muted">Use the dropdowns to setup your import mapping.</p>
          <div class="row">
            <div class="col-12">
              <div
                :class="[
                  'm-0',
                  'table-border',
                  'table-responsive',
                  samplesOverflown && 'overflown',
                ]"
              >
                <table class="table table-nowrap">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        v-for="(value, idx) in samples[0]"
                        :key="idx"
                        style="min-width: 200px"
                      >
                        <div class="mb-2">{{ idx }}</div>
                        <select class="form-control" @change="mapField(idx, $event)">
                          <option value="" selected>Map to...</option>
                          <option v-for="(field, idx) in fields" :key="idx" :value="field">
                            {{ field.charAt(0).toUpperCase() + field.slice(1) }}
                          </option>
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(sample, idx) in samples" :key="idx">
                      <td scope="col" v-for="(value, idx) in sample" :key="idx">{{ value }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isImporting"
      role="alert"
      class="mb-4 alert alert-light m-0 d-flex align-items-center"
    >
      <i class="fas fa-info-circle mr-2"></i>
      <p class="m-0">
        Your import is being processed. Feel free to navigate to other pages. We will notify you
        when it's completed.
      </p>
    </div>
    <button
      @click="startImport"
      :disabled="!hasFinishedMappingFields || isImporting"
      class="btn btn-primary btn-lg mr-3"
      :class="[isImporting ? 'is-loading is-loading-sm' : '']"
    >
      Import {{ count }} Customer{{ count > 1 ? 's ' : '' }}
    </button>
    <a href="javascript:;" @click="cancel()" v-if="!isImporting" class="btn btn-link text-muted"
      >Cancel</a
    >
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const CustomerModule = createNamespacedHelpers('customer')
const userModule = createNamespacedHelpers('user')

export default {
  name: 'CustomerImportPreflight',

  props: ['preflightResponse'],

  components: {},

  data: () => ({
    fields: ['phone', 'name', 'email', 'tag'],
    mappedFields: {},
    headers: [],
    samples: [],
    count: 0,
    hasFinishedMappingFields: false,
    isImporting: false,
  }),

  computed: {
    ...CustomerModule.mapGetters(['selectImportParams']),
    ...userModule.mapState(['onlineUser']),
    samplesOverflown() {
      console.log(this.samples[0], Object.keys(this.samples[0]).length)
      return Object.keys(this.samples[0]).length > 4
    },
  },

  async mounted() {
    this.count = this.preflightResponse.count
    this.samples = this.preflightResponse.samples
  },

  methods: {
    ...CustomerModule.mapActions(['importCustomers']),
    async startImport() {
      this.isImporting = true

      try {
        await this.importCustomers({
          mappedFields: this.mappedFields,
          preflight: false,
        })
      } catch (error) {
        this.$notify({
          title: 'Import failed',
          text: `There was a problem. Contact us for help.`,
          type: 'error',
        })
      }
    },
    mapField(index, event) {
      const mapTo = event.target.value
      if (event.target.value === '') {
        delete this.mappedFields[index]
        this.hasFinishedMappingFields = false
        return
      }
      for (const key of Object.keys(this.mappedFields)) {
        if (this.mappedFields[key] == mapTo) {
          this.$notify({
            title: 'Already mapped.',
            text: `You've already mapped ${mapTo} to ${index}`,
            type: 'error',
          })
          event.target.value = this.mappedFields[index]
          this.hasFinishedMappingFields = false
          return
        }
      }

      this.mappedFields[index] = mapTo
      this.hasFinishedMappingFields = this.hasMappedFields()
    },
    hasMappedFields() {
      return Object.values(this.mappedFields).some((f) => f === 'phone')
    },
    cancel() {
      this.$router.push({
        name: 'customers',
      })
    },
  },
}
</script>

<style scoped lang="scss">
.table-border {
  border: 1px solid #edf2f9;

  &.overflown {
    border-right: 0;
  }
}
</style>
