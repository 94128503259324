<template>
  <div class="card">
    <div class="card-body">
      <h2 class="mt-3 mb-2">Setup your Import</h2>
      <p class="mb-4 text-muted" v-if="showActions">
        Once you pick an action and location, you can upload your file.
      </p>
      <form class="mb-0">
        <div v-if="showActions">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label>
                  Choose an invitation
                  <span class="text-muted"></span>
                </label>
                <small class="form-text text-muted"
                  >After the customers are imported, what would you like to do?</small
                >
                <select class="form-control form-control-lg" v-model="action">
                  <option value selected disabled>Choose one</option>
                  <option value="none">Do nothing</option>
                  <option value="survey">Invite them to leave a survey</option>
                  <!-- <option value="review">
                  Invite them to leave a review
                    </option>-->
                </select>
              </div>
            </div>
          </div>
          <hr class="mt-3 mb-4" />
        </div>
        <CustomerImportLocationDropdown v-on:setLocation="setLocation" />
        <hr class="mt-3 mb-4" />
        <div class="form-group">
          <label class="mb-1">Marketing Eligibility</label>
          <small class="form-text text-muted"
            >Marketing opt-in was previously gathered, making these customers eligible to receive
            campaigns.</small
          >
          <select class="form-control" data-toggle="select" v-model="marketingOptIn">
            <option :value="undefined" selected disabled>Choose one</option>
            <option :value="true">Eligible to receive marketing</option>
            <option :value="false">Not eligible</option>
          </select>
        </div>
        <hr v-if="action && locationId && marketingOptIn !== undefined" class="mt-4 mb-4" />
        <CustomerImportUpload
          v-if="action && locationId && marketingOptIn !== undefined"
          v-on:finished="startPreflight"
        />
        <div
          v-if="fileUploaded"
          class="alert alert-light m-0 d-flex justify-content-center align-items-center"
          role="alert"
        >
          <b-spinner small label="Spinning" class="mr-1"></b-spinner>
          <h3 class="m-0">We are verifying your uploaded CSV file...</h3>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TCPAService from '@/services/TcpaService'
import CustomerImportUpload from './CustomerImportUpload'
import CustomerImportLocationDropdown from './CustomerImportLocationDropdown'

const companyModule = createNamespacedHelpers('company')
const CustomerModule = createNamespacedHelpers('customer')
const userModule = createNamespacedHelpers('user')

export default {
  name: 'CustomerImportForm',

  components: {
    CustomerImportUpload,
    CustomerImportLocationDropdown,
  },

  data: () => ({
    action: '',
    locationId: undefined,
    locations: [],
    marketingOptIn: undefined,
    fileUploaded: false,
  }),

  mounted() {
    if (!this.showActions) {
      this.action = 'none'
    }
  },

  computed: {
    ...userModule.mapState(['onlineUser']),
    ...companyModule.mapState(['activeCompany']),
    showActions() {
      return (
        this.activeCompany._id !== 'ALL_COMPANIES' &&
        this.activeCompany.settings.bulkSurveyInvitesEnabled
      )
    },
  },

  methods: {
    ...CustomerModule.mapActions(['importCustomers', 'setImportParams']),
    async startPreflight(fileUrl) {
      this.setImportParams({
        fileUrl,
        action: this.action,
        locationId: this.locationId,
        preflight: true,
        marketingOptIn: this.marketingOptIn,
      })

      try {
        await this.importCustomers({
          preflight: true,
        })

        await this.saveTcpaCompliance(fileUrl)

        this.fileUploaded = true
      } catch (error) {
        this.$notify({
          title: 'Import failed',
          text: `Please double check your CSV file.`,
          type: 'error',
        })
      }
    },
    async saveTcpaCompliance(fileUrl) {
      await TCPAService.create({
        userId: this.onlineUser._id,
        companyId: this.activeCompany._id,
        locationId: this.locationId,
        contactListUrl: fileUrl,
      })
    },
    setLocation(locationId) {
      this.locationId = locationId
    },
  },
}
</script>

<style scoped lang="scss"></style>
