<template>
  <div ref="uploader"></div>
</template>

<script>
import Uppy from '@uppy/core'
import FileInput from '@uppy/file-input'
import firebase from 'firebase/app'
import 'firebase/storage'

// @group Customer Import
export default {
  name: 'FileUploader',
  props: {
    // The text to render in the upload button
    buttonText: {
      type: String,
      required: true,
    },
    // The style for the upload button
    buttonClass: {
      type: String,
      required: true,
    },
  },
  watch: {
    buttonText() {
      this.$refs.uploader.querySelector('button').innerText = this.buttonText
    },
  },
  data: () => ({
    modalTitle: 'TCPA Compliance Agreement',
    modalMessage: `
        By pressing "Agree" you confirm that the names and/or phone numbers that you are uploading
        are compliant with respect to all applicable telemarketing rules and laws. If this list is 
        to be used for marketing purposes, you confirm that you have express written consent for 
        all recipients to send any message.
        `,
  }),
  methods: {
    // @vuese
    // Uploads selected file to Firebase and then emits the URL
    // @arg file: the selected file, RETURNS: none
    async uploadFile(file) {
      const button = this.$refs.uploader.querySelector('button')
      button.classList.add('is-loading')
      button.disabled = true

      const randomString = Math.random().toString(36).substring(7)
      const fileExtension = file.name.split('.').pop()
      const storageRef = firebase.storage().ref().child(`uploads/${randomString}.${fileExtension}`)

      const snap = await storageRef.put(file.data)
      const url = await snap.ref.getDownloadURL()

      this.$refs.uploader.querySelector('button').classList.add('is-loading')

      // Notifies parent that a file was uploaded and passes URL
      // @arg the URL of the file in Firebase
      this.$emit('uploaded', url)
      button.classList.remove('is-loading')
      button.disabled = false
    },
  },
  mounted() {
    const uppy = new Uppy({
      restrictions: {
        allowedFileTypes: ['image/*', 'text/csv'],
      },
      onBeforeFileAdded: (file) => {
        const h = this.$createElement
        const titleVNode = h('div', [
          h('h2', { class: ['mb-0'], domProps: { innerHTML: `${this.modalTitle}` } }),
        ])
        this.$bvModal
          .msgBoxConfirm(this.modalMessage, {
            title: [titleVNode],
            okTitle: 'AGREE',
            cancelTitle: 'DISAGREE',
            cancelVariant: 'danger',
            footerClass: 'p-4',
            centered: true,
          })
          .then((value) => {
            if (value) this.uploadFile(file)
            else return
          })
          .catch((err) => {
            console.log(err)
          })
      },
    })

    uppy.use(FileInput, {
      target: this.$refs.uploader,
      locale: {
        strings: {
          chooseFiles: this.buttonText,
        },
      },
    })

    this.$refs.uploader
      .querySelector('button')
      .classList.add('btn', this.buttonClass || 'btn-white')
  },
}
</script>

<style scoped lang="scss">
::v-deep .btn-white {
  border-color: #d2ddec;
}
</style>
