<template>
  <div class="row mb-4">
    <div class="col-12 stepper">
      <div class="mt-3">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: this.progress + '%' }"
          ></div>
        </div>
        <div class="stepper-inner">
          <a href="javascript:;" class="btn btn-rounded-circle btn-primary step float-left">
            <span v-if="state == 'upload'">1</span>
            <span v-else class="fe fe-check"></span>
          </a>
          <a
            href="javascript:;"
            class="btn btn-rounded-circle btn-primary step mx-auto"
            :class="[isImporting ? 'is-loading is-loading-sm' : '']"
          >
            <span v-if="state == 'preflight'" class="fe fe-check"></span>
            <span v-else>2</span>
          </a>
          <a href="javascript:;" class="btn btn-rounded-circle btn-primary step float-right"> 3 </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerImportStepper',
  props: ['state'],
  data: () => ({
    isUploading: false,
    isImporting: false,
  }),
  computed: {
    progress() {
      if (this.state === 'upload') {
        return 0
      } else if (this.state === 'preflight') {
        return 50
      } else if (this.state === 'summary') {
        return 95
      } else if (this.state === 'done') {
        return 100
      }
      return 0
    },
  },
}
</script>

<style scoped lang="scss"></style>
