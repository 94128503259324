<template>
  <div>
    <div v-if="errors.length">
      <div class="card">
        <div class="card-body">
          <h2 class="mt-3 mb-2">
            <span class="text-primary">{{ errors.length }}</span> customer{{
              errors.length > 1 ? 's were' : ' was'
            }}
            unable to be imported.
          </h2>
          <p class="mb-4 text-muted">Here is a list of those that didn't work out.</p>

          <div class="col-12">
            <table class="table table-nowrap">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Reason</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(error, idx) in errors" :key="idx">
                  <th scope="row">{{ idx + 1 }}</th>
                  <td>{{ error.name }}</td>
                  <td>{{ error.email }}</td>
                  <td>{{ error.phone }}</td>
                  <td class="text-danger">{{ error.error }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <button @click="done" class="btn btn-primary btn-lg mr-3">Continue</button>
  </div>
</template>

<script>
export default {
  name: 'CustomerImportSummary',
  props: ['importResults'],
  components: {},
  data: () => ({
    errors: [],
    count: 0,
  }),
  computed: {},
  methods: {
    done() {
      this.$emit('done')
    },
  },
  async mounted() {
    this.errors = this.importResults.errors
    this.count = this.importResults.count
  },
}
</script>

<style scoped lang="scss"></style>
