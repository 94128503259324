<template>
  <div class="card col-12">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/no-customers.svg"
            alt="..."
            class="img-fluid mt--5 mb-4"
            style="max-width: 272px"
          />

          <!-- <div> -->
          <h2>{{ count }} customers were imported.</h2>

          <p class="text-muted">Way to grow your database, and your business.</p>
          <router-link :to="{ name: 'customers' }" class="btn btn-primary btn-lg">
            Awesome!
          </router-link>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['count'],
  name: 'CustomerImportDoneState',
  mounted() {
    if (!this.count) {
      this.$router.push({ name: 'customers' })
    }
  },
}
</script>

<style scoped lang="scss"></style>
