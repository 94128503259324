<template>
  <MainContent>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-10">
          <CustomerImportHeader />
          <CustomerImportStepper :state="state" class="mb-5" />
          <CustomerImportForm v-if="state === 'upload'" />
          <CustomerImportPreflight
            v-if="state === 'preflight'"
            :preflightResponse="preflightResponse"
          />
          <CustomerImportSummary
            v-if="state === 'summary'"
            :importResults="importResults"
            @done="done"
          />
          <CustomerImportDoneState
            v-if="state === 'done'"
            :count="importResults.count - importResults.errors.length"
          />
        </div>
      </div>
    </div>
  </MainContent>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import MainContent from '@/components/MainContent/MainContent'
import CustomerImportHeader from '@/components/Modules/Customer/CustomerImport/CustomerImportHeader'
import CustomerImportStepper from '@/components/Modules/Customer/CustomerImport/CustomerImportStepper'
import CustomerImportForm from '@/components/Modules/Customer/CustomerImport/CustomerImportForm'
import CustomerImportPreflight from '@/components/Modules/Customer/CustomerImport/CustomerImportPreflight'
import CustomerImportSummary from '@/components/Modules/Customer/CustomerImport/CustomerImportSummary'
import CustomerImportDoneState from '@/components/Modules/Customer/CustomerImport/CustomerImportDoneState'
import { trackEvent } from '@/lib/analytics'

const userModule = createNamespacedHelpers('user')

const WS_EVENT_TYPES = {
  CustomersImportRequested: 'customers-import-requested',
  CustomersImportSampled: 'customers-import-sampled',
  CustomersImported: 'customers-imported',
  CustomersImportFailed: 'customers-import-failed',
}

export default {
  name: 'CustomersImportPage',
  components: {
    MainContent,
    CustomerImportHeader,
    CustomerImportStepper,
    CustomerImportForm,
    CustomerImportPreflight,
    CustomerImportSummary,
    CustomerImportDoneState,
  },

  data: () => ({
    state: 'upload',
    preflightResponse: {},
    importResults: {},
  }),

  computed: {
    ...userModule.mapState(['onlineUser']),
  },

  mounted() {
    trackEvent(this.$intercom, 'Customer Import')

    this.pushWSMessageHandler({ key: 'customers-import', handler: this.onWSMessage })
  },

  methods: {
    ...userModule.mapActions(['pushWSMessageHandler']),
    onWSMessage(message) {
      const { type, detail } = message

      if (this.onlineUser._id !== detail.userId) {
        console.log('WS unmatched client', type)
        return
      }

      console.log('WS message:', { type, detail })

      switch (type) {
        case WS_EVENT_TYPES.CustomersImportSampled:
          this.preflightResponse = detail
          this.state = 'preflight'
          break
        case WS_EVENT_TYPES.CustomersImported:
          this.showSummary(detail)
          break
        case WS_EVENT_TYPES.CustomersImportFailed:
          this.$notify({
            type: 'error',
            title: `Importing ${detail.count} customers failed! Try again`,
          })
          break
        default:
          console.log('WS unhandle message:', type)
      }
    },
    showSummary(results) {
      this.importResults = results

      this.$notify({
        title: 'Import completed',
        duration: 30000,
        ignoreDuplicates: true,
        text: `${results.count - results.errors.length} customers imported.`,
      })

      if (results.errors.length) {
        this.state = 'summary'
        return
      }

      this.done()
    },
    done() {
      this.state = 'done'
    },
  },
}
</script>

<style scoped lang="scss"></style>
