<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-group">
        <label>Choose a location</label>
        <small class="form-text text-muted"
          >The location to which the customer(s) will be assigned.</small
        >
        <select
          @change="selectLocation($event)"
          v-model="selectedLocationId"
          class="form-control"
          data-toggle="select"
        >
          <option selected value="" disabled>Choose one</option>
          <option
            v-for="(location, idx) in userActiveCompanyLocations"
            :key="idx"
            :value="location._id"
          >
            {{ location.friendlyName || location.city }} ({{
              location.addressDetails.streetNumber
            }}
            {{ location.addressDetails.streetName }})
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters, mapActions } from 'vuex'
const userState = createNamespacedHelpers('user').mapState
const companyState = createNamespacedHelpers('company').mapState
export default {
  name: 'CustomerImportLocationDropdown',
  computed: {
    ...companyState(['activeCompany']),
    ...userState(['onlineUser']),
    ...mapGetters('location', ['userActiveCompanyLocations']),
  },
  data: () => ({
    selectedLocationId: '',
  }),
  methods: {
    ...mapActions('location', ['getUserLocationsByActiveCompany']),
    selectLocation(event) {
      this.$emit('setLocation', event.target.value)
    },
  },
  async mounted() {
    await this.getUserLocationsByActiveCompany()
    if (this.userActiveCompanyLocations.length === 1) {
      this.selectLocation({ target: { value: this.userActiveCompanyLocations[0]._id } })
    }
  },
}
</script>

<style scoped lang="scss"></style>
